var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "550" },
      model: {
        value: _vm.modal.show,
        callback: function($$v) {
          _vm.$set(_vm.modal, "show", $$v)
        },
        expression: "modal.show"
      }
    },
    [
      _c(
        "v-card",
        [
          !_vm.showForm && _vm.canCancel
            ? _c(
                "v-card-actions",
                { staticClass: "row pb-0" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-2",
                      attrs: { icon: "", small: "" },
                      on: { click: _vm.showCancelForm }
                    },
                    [_c("v-icon", [_vm._v("mdi-trash-can-outline")])],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-card-title",
            { staticClass: "row pt-0" },
            [
              _c(
                "v-icon",
                {
                  staticClass: "col-1 pb-0",
                  attrs: { color: _vm.calendar.color || "primary" }
                },
                [_vm._v("mdi-checkbox-blank")]
              ),
              _c("h3", { staticClass: "col-11 pb-0" }, [
                _vm._v(_vm._s(_vm.summary))
              ]),
              _c(
                "h4",
                {
                  staticClass: "col-11 offset-1 grey--text text--darken-2 py-0"
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.moment(_vm.event.start).format("dddd, MMMM Do YYYY")
                      ) +
                      " "
                  ),
                  !_vm.event.allDay &&
                  _vm.event.extendedProps &&
                    !_vm.event.extendedProps.multipleDays
                    ? _c("span", [
                        _vm._v(
                          " . " +
                            _vm._s(
                              _vm.moment(_vm.event.start).format("hh:mm A")
                            ) +
                            " - " +
                            _vm._s(
                              _vm.moment(_vm.event.end).format("hh:mm A")
                            ) +
                            " "
                        )
                      ])
                    : _vm._e(),
                  _vm.event.extendedProps &&
                  _vm.event.extendedProps.multipleDays
                    ? _c("span", [
                        _vm._v(
                          " - " +
                            _vm._s(
                              _vm
                                .moment(_vm.event.end)
                                .format("dddd, MMMM Do YYYY")
                            ) +
                            " "
                        )
                      ])
                    : _vm._e()
                ]
              )
            ],
            1
          ),
          _c("v-card-text", { staticClass: "row py-0" }, [
            _vm.googleEvent
              ? _c(
                  "div",
                  { staticClass: "col-11 offset-1" },
                  [
                    _vm.googleEvent.link
                      ? _c(
                          "v-btn",
                          {
                            attrs: {
                              href: _vm.googleEvent.link,
                              target: "_blank",
                              depressed: "",
                              small: "",
                              color: "primary"
                            }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("layout.joinMeeting")) + " "
                            )
                          ]
                        )
                      : _vm._e(),
                    _c("p", [_vm._v(_vm._s(_vm.googleEvent.link))]),
                    _vm.googleEvent.location
                      ? _c(
                          "p",
                          { staticClass: "mb-1" },
                          [
                            _c("v-icon", { attrs: { small: "" } }, [
                              _vm._v("mdi-map-marker")
                            ]),
                            _vm._v(_vm._s(_vm.googleEvent.location))
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.googleEvent.organizer
                      ? _c(
                          "p",
                          { staticClass: "mb-1" },
                          [
                            _c("v-icon", { attrs: { small: "" } }, [
                              _vm._v("mdi-calendar")
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("models.calendar.attributes.createdBy")
                                ) +
                                ": " +
                                _vm._s(_vm.googleEvent.organizer.email) +
                                " "
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              : _vm.canCancel
              ? _c(
                  "div",
                  { staticClass: "col-11 offset-1 text-right" },
                  [
                    _vm.showForm
                      ? _c("app-form", {
                          attrs: {
                            fields: _vm.cancellationFields,
                            "submit-button": _vm.submitButton,
                            cancelButton: _vm.cancelButton
                          },
                          on: {
                            submitted: _vm.cancelLeaveDate,
                            canceled: function($event) {
                              _vm.showForm = false
                            }
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
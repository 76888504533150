var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-lg-5", attrs: { fluid: "" } },
    [_c("calendar")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.currentUser.googleSynced &&
    _vm.calendars[_vm.calendar.GOOGLE_EVENTS]
    ? _c("v-btn", { on: { click: _vm.handleAuthClick } }, [
        _vm._v(" " + _vm._s(_vm.$t("layout.syncWithGoogle")) + " ")
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
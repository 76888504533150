var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("google-auth", { on: { synced: _vm.syncWithGoogle } }),
      _c(
        "div",
        { staticClass: "row" },
        [
          _c("app-modal", {
            attrs: { modal: _vm.modal },
            on: { "submit-modal": _vm.submitCalendarConfiguration }
          }),
          _c("event-modal", {
            attrs: {
              modal: _vm.eventModal,
              event: _vm.selectedEvent,
              calendar: _vm.selectedCalendar
            }
          }),
          _c(
            "div",
            { staticClass: "col-md-9 col-sm-12" },
            [
              _c("FullCalendar", {
                ref: "calendar",
                attrs: { options: _vm.calendarOptions }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-3 col-sm-12" },
            [
              _c("v-date-picker", {
                staticClass: "py-2",
                attrs: { color: "primary", "full-width": "", flat: "" },
                model: {
                  value: _vm.miniMapDate,
                  callback: function($$v) {
                    _vm.miniMapDate = $$v
                  },
                  expression: "miniMapDate"
                }
              }),
              _c("h4", { staticClass: "mt-5 mb-2", attrs: { color: "text" } }, [
                _vm._v(_vm._s(_vm.$t("layout.mainMenu.myCalendars")))
              ]),
              _vm._l(_vm.calendars, function(item, index) {
                return _c(
                  "div",
                  { key: item.key, staticClass: "row px-3" },
                  [
                    _c("v-checkbox", {
                      staticClass: "col-10 my-0 py-0",
                      attrs: {
                        color: item.color || "primary",
                        label: item.name
                      },
                      model: {
                        value: item.show,
                        callback: function($$v) {
                          _vm.$set(item, "show", $$v)
                        },
                        expression: "item.show"
                      }
                    }),
                    _c(
                      "div",
                      { staticClass: "col-2 py-0" },
                      [
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            attrs: { icon: "", small: "" },
                            on: {
                              click: function($event) {
                                return _vm.openCalendarConfigurationsModal(
                                  item,
                                  index
                                )
                              }
                            }
                          },
                          [_c("v-icon", [_vm._v("mdi-pen")])],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }